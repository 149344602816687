<template>
  <CModal
    :title="$t('create_holiday_date')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('type')"
          :options="holidayTypeOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.holiday_type_id"
          :isValid="valid_formData_holiday_type_id"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('holiday_date') }}
          </label>
          <div class="col-sm-9">
            <date-picker
              v-model="formData.holiday_date"
              :shortcuts="false"
              :editable="false"
              :not-before="$moment()"
              width="100%"
              type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white', valid_formData_holiday_date ? 'is-valid' : 'is-invalid']"
            />
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CreateHolidayDateModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      holidayTypeOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('holiday_type') + '...' },
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { holiday_type_id: null, holiday_date: null }
      }
    }
  },
  computed: {
    valid_formData_holiday_type_id () {
      return this.formData.holiday_type_id ? true : false
    },
    valid_formData_holiday_date () {
      return this.formData.holiday_date && this.$moment(this.formData.holiday_date).isValid() ? true : false
    },
    valid_submit () {
      return this.valid_formData_holiday_type_id && this.valid_formData_holiday_date
    }
  },
  mounted: function () {
    this.getHolidayTypes()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getHolidayTypes () {
      const loader = this.$loading.show()
      this.$store.dispatch('getHolidayTypes') .then(res => {
        this.holidayTypeOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('holiday_type') + '...' }]
        for (const i in res) {
          if (res[i].default) continue
          this.holidayTypeOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeHolidayDate', this.$_.extend(this.$_.clone(this.formData), { holiday_date: this.$moment(this.formData.holiday_date).format('YYYY-MM-DD') })).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
